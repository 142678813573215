<template>
  <div class="row">
    <div class="flex xs12 md6">
      <h6>{{ $t('trainings.trainings.inputs.imagePath') }}</h6>
      <picture-input
        ref="image_path"
        width="300"
        height="300"
        margin="16"
        size="10"
        :crop="false"
        :prefill="prefill.image_path"
        button-class="va-button va-button--flat color--primary"
        :custom-strings="imageTranslations"
        @change="onChange"
      />
    </div>
    <div class="flex xs12 md6">
      <div class="row">
        <div class="flex xs12">
          <va-input
            color="info"
            v-model="title"
            :disabled="loading"
            :label="$t('trainings.trainings.inputs.titleInput')"
          />
        </div>
        <div class="flex xs12">
          <h6>{{ $t('trainings.trainings.inputs.descriptionInput') }}</h6>
        </div>
        <div class="flex xs12">
          <quill-editor
            class="editor"
            ref="textEditor"
            :value="descriptionContent"
            :options="editorOption"
            @change="onEditorChange"
          />
        </div>
      </div>
    </div>
    <div class="flex xs12" v-if="!wizard">
      <va-button
        color="primary"
        type="button"
        :disabled="loading"
        @click.prevent="submit"
      >
        <text-loading
          :loading="loading"
          icon="fa fa-save"
        >
          {{ $t('layout.form.save') }}
        </text-loading>
      </va-button>
    </div>
  </div>
</template>
<script>
import { quillEditor } from 'vue-quill-editor'
import debounce from 'lodash/debounce'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

const PictureInput = () => import(/* webpackPrefetch: true */ 'vue-picture-input')

export default {
  name: 'training-public-details',
  components: {
    quillEditor,
    PictureInput,
  },
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    wizard: {
      type: Boolean,
      default: function () { return false },
    },
    training: {
      type: Object,
      required: false,
      default: function () {
        return {
          id: 0,
        }
      },
    },
  },
  data () {
    return {
      prefill: {
        image_path: null,
      },
      images: {
        image_path: null,
      },
      title: '',
      descriptionContent: '',
      imageTranslations: {
        upload: this.$t('layout.images.upload_error'),
        drag: this.$t('layout.images.drag'),
        tap: this.$t('layout.images.tap'),
        change: this.$t('layout.images.change'),
        remove: this.$t('layout.images.remove'),
        select: this.$t('layout.images.select'),
        selected: this.$t('layout.images.selected'),
        fileSize: this.$t('layout.images.file_size', { size: 10 }),
        fileType: this.$t('layout.images.file_type'),
        aspect: this.$t('layout.images.aspect'),
      },
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['clean'],
            ['link', 'image', 'video'],
          ],
        },
      },
    }
  },
  watch: {
    async training (val) {
      if (val.image_path) {
        let base = ''
        if (process.env.NODE_ENV === 'development') {
          base = this.$http.defaults.baseURL.match(/^https?:\/\/[^/]+/g)[0]
        }
        this.prefill.image_path = base + val.image_path
      }

      if (val.title) {
        this.title = val.title
      }
      if (val.description) {
        this.descriptionContent = val.description
      }
    },
  },
  methods: {
    onChange () {
      const ref = 'image_path'
      const source = 'image_path'
      if (!this.$refs[ref].file) {
        return console.error('FileReader API not supported: use the <form>, Luke!')
      }
      this.images[source] = this.$refs[ref].file
    },
    onEditorChange: debounce(function (value) {
      this.descriptionContent = value.html
    }, 466),
    submit () {
      const data = {
        title: this.title,
        description: this.descriptionContent,
        image_path: this.images.image_path,
      }
      this.$emit('submit', data)
    },
  },
}
</script>
